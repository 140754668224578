import { getItem, setItem } from '@buzzeasy/shared-frontend-utilities';
import dayjs from 'dayjs';
import { PropsWithChildren, createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import environmentConfig from '../../environmentConfig';
import { getSystemColorScheme } from '../../utils/cssUtils';

const { environment } = environmentConfig;

export const dateFormats = ['iso', 'eu', 'us', 'written'] as const;
export const timeFormats = ['24hour', '24hour+sec', '12hour', '12hour+sec'] as const;
export const timeSpanFormats = ['normal', 'written'] as const;

export interface Settings {
  colorScheme: null | 'light' | 'dark';
  firstDayOfWeek: number;
  dateFormat: null | typeof dateFormats[number];
  timeFormat: null | typeof timeFormats[number];
  timeSpanFormat: typeof timeSpanFormats[number];
  language: string;
  showDebugInfo: boolean;
  ringtoneVolume: number; // float between 0 and 1
}

const localStorageKeys: Record<keyof Settings, string> = {
  colorScheme: 'aui-colorScheme',
  firstDayOfWeek: 'aui-firstDayOfWeek',
  dateFormat: 'aui-dateFormat',
  timeFormat: 'aui-timeFormat',
  timeSpanFormat: 'aui-timeSpanFormat',
  language: 'aui-language',
  showDebugInfo: 'aui-showDebugInfo',
  ringtoneVolume: 'aui-ringtoneVolume',
};

export const languageLocalStorageKey = localStorageKeys.language;

type ContextValue = [
  settings: Settings,
  setSettings: (value: Partial<Settings> | ((currentSettings: Settings) => Partial<Settings>)) => void,
];

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const SettingsContext = createContext<ContextValue>(undefined!);

/**
 * Provides the value for SettingsContext.
 *
 * **No provider dependencies**
 */
export default function SettingsProvider({ children }: PropsWithChildren) {
  const { i18n } = useTranslation();

  const [settings, setSettings] = useState<Settings>({
    colorScheme: getItem<Settings['colorScheme']>(localStorageKeys.colorScheme, getSystemColorScheme()),
    firstDayOfWeek: getItem<Settings['firstDayOfWeek']>(localStorageKeys.firstDayOfWeek, 1),
    dateFormat: getItem<Settings['dateFormat']>(localStorageKeys.dateFormat, null),
    timeFormat: getItem<Settings['timeFormat']>(localStorageKeys.timeFormat, null),
    timeSpanFormat: getItem<Settings['timeSpanFormat']>(localStorageKeys.timeSpanFormat, 'normal'),
    language: getItem<Settings['language']>(localStorageKeys.language, 'en-US'),
    showDebugInfo: getItem<Settings['showDebugInfo']>(localStorageKeys.showDebugInfo, environment !== 'prod'),
    ringtoneVolume: getItem<Settings['ringtoneVolume']>(localStorageKeys.ringtoneVolume, 0.5),
  });

  const settingsRef = useRef(settings);
  settingsRef.current = settings;

  const handleUpdateSideEffects = useCallback(
    <TKey extends keyof Settings>(settingsKey: TKey, value: Settings[TKey]) => {
      switch (settingsKey) {
        case 'firstDayOfWeek':
          dayjs.updateLocale(dayjs.locale(), { weekStart: value as Settings['firstDayOfWeek'] });
          break;
        case 'language':
          i18n.changeLanguage(value as Settings['language']);
          break;
      }
    },
    [i18n],
  );

  useEffect(
    () => {
      Object.keys(settings).forEach((k) => {
        const key = k as keyof Settings;
        const val = settings[key] as Settings[typeof key];

        handleUpdateSideEffects(key, val);
      });
    },
    [handleUpdateSideEffects, settings],
  );

  const updateSettings = useCallback(
    (value: Partial<Settings> | ((currentSettings: Settings) => Partial<Settings>)) => {
      const handleUpdate = (partialSettings: Partial<Settings>) => {
        Object.keys(partialSettings).forEach((key) => setItem(localStorageKeys[key as keyof Settings], partialSettings[key as keyof Settings]));
        setSettings(curr => ({ ...curr, ...partialSettings }));
      };

      if (typeof value === 'function')
        handleUpdate(value(settingsRef.current));
      else
        handleUpdate(value);
    },
    [],
  );

  const contextValue = useMemo<ContextValue>(
    () => [settings, updateSettings],
    [settings, updateSettings],
  );

  return (
    <SettingsContext.Provider value={contextValue}>
      {children}
    </SettingsContext.Provider>
  );
}